import { WhiteWrapper, Wrapper } from "../Common/styles.common";
import React, { useEffect, useState } from "react";
import { strings } from "utils/constants/strings";
import useVendorStore from "../HomePage/useVendorStore";
import { Loader } from "../Common/Loader";
import { FilterBox } from "./styles.po";
import {
    ORDER_STATUS_DISPATCHED,
    PO_ORDER_TAG,
    PO_ORDER_TAG_DIRECT_DELIVERY,
    PO_ORDER_TAG_MANDI_DD,
    PO_STATUS_APPROVED,
    SELLER_ACTION_STATUS_COMPLETED,
    SELLER_ACTION_STATUS_PENDING, STATUS_BEGIN, STATUS_DONE, STATUS_FAIL,
} from "utils/constants/others";
import InfiniteScroll from "react-infinite-scroll-component";
import BackButton from "../Button/BackButton";
import { BottomModal, Button, FlexColumn } from "../Common/styles.common";
import Cross from "sushiweb/Icons/all/Cross";
import DDPoCard from "./DDPoCard";
import {DDCompletedCard} from "./DDCompletedCard";

const PoTypeBox = ({ id, activeBox, handleClick, children }) => {
    return (
        <React.Fragment>
            <FilterBox
                clicked={id === activeBox}
                onClick={() => handleClick(id)}
                className="fs-13 fw-500"
            >
                {children}
            </FilterBox>
        </React.Fragment>
    );
};

const AllDDPoView = () => {
    const {
        getPurchaseOrders,
        purchaseOrders,
        poCount,
        grnCount,
        getPurchaseOrderStatus,
    } = useVendorStore();

    const [activeBox, setActiveBox] = useState(0);
    const outletId = window.localStorage.getItem("outlet_id");
    const [pageNumber, setPageNumber] = useState(1)
    const [phoneNumber, setPhoneNumber] = useState("")
    const [poCreator, setPoCreator] = useState("")
    const [openCallModal, setOpenCallModal] = useState(false)
    const [showLoader, setShowLoader] = useState(false);

    const handleClick = (index) => {
        setActiveBox(index);
    };

    useEffect(() => {
        if (activeBox === 0) {
            getPurchaseOrders({
                vendor_id: outletId,
                is_item_required: true,
                po_status: PO_STATUS_APPROVED,
                fetch_invoice: true,
                fetch_po_with_no_seller_invoice: true,
                order_tags :`${PO_ORDER_TAG_MANDI_DD},${PO_ORDER_TAG_DIRECT_DELIVERY}`,
            },true);
        } else if (activeBox === 1) {
            getPurchaseOrders({
                vendor_id: outletId,
                is_item_required: true,
                fetch_invoice: true,
                fetch_po_with_no_seller_invoice: false,
                order_status: `${ORDER_STATUS_DISPATCHED},${SELLER_ACTION_STATUS_COMPLETED}`,
                seller_action_status: SELLER_ACTION_STATUS_PENDING,
                order_tags: `${PO_ORDER_TAG_MANDI_DD},${PO_ORDER_TAG_DIRECT_DELIVERY}`,
                po_status: PO_STATUS_APPROVED,
            },true);
        } else if (activeBox === 2) {
            getPurchaseOrders({
                vendor_id: outletId,
                is_item_required: true,
                fetch_invoice: true,
                fetch_po_with_no_seller_invoice: false,
                seller_action_status: SELLER_ACTION_STATUS_COMPLETED,
                is_payment_details_required: true,
                order_tags: `${PO_ORDER_TAG_MANDI_DD},${PO_ORDER_TAG_DIRECT_DELIVERY}`,
            },true);
        }
        setPageNumber(1);
    }, [activeBox]);

    useEffect(() => {
        if (getPurchaseOrderStatus === STATUS_BEGIN) {
            setShowLoader(true);
        }
        if (getPurchaseOrderStatus === STATUS_DONE) {
            setShowLoader(false);
        }
        if (getPurchaseOrderStatus === STATUS_FAIL) {
            setShowLoader(false);
        }
    }, [getPurchaseOrderStatus]);

    let cards = [];
    if (activeBox === 0) {
        if (purchaseOrders && purchaseOrders.length) {
            purchaseOrders.map((p, index) => {
                cards.push(<DDPoCard
                    key={index}
                    po={p}
                    source={"all"}
                    openCallModal={openCallModal}
                    setOpenCallModal={setOpenCallModal}
                    setPhoneNumber={setPhoneNumber}
                    setPoCreator={setPoCreator}
                />);
            })
        }
    } else if (activeBox === 1) {
        if (purchaseOrders && purchaseOrders.length) {
            purchaseOrders.map((p, index) => {
                cards.push(<DDPoCard
                    key={index}
                    po={p}
                    source={"all"}
                    openCallModal={openCallModal}
                    setOpenCallModal={setOpenCallModal}
                    setPhoneNumber={setPhoneNumber}
                    setPoCreator={setPoCreator}
                    type="pending"
                />);
            })
        }
    } else if (activeBox === 2) {
        if (purchaseOrders && purchaseOrders.length) {
            for (let index = 0; index < purchaseOrders.length; index++) {
                cards.push(<DDCompletedCard key={index} po={purchaseOrders[index]} />);
            }
        }
    }

    const handleCall = () => {
        const formattedPhoneNumber = phoneNumber.replace(/\s/g, '');
        window.location.href = `tel:+91${formattedPhoneNumber}`;
    };

    const loadNextPage = () => {
        if (activeBox === 0) {
            getPurchaseOrders({
                page_no:pageNumber+1,
                vendor_id: outletId,
                is_item_required: true,
                po_status: PO_STATUS_APPROVED,
                fetch_invoice: true,
                fetch_po_with_no_seller_invoice: true,
                order_tags :`${PO_ORDER_TAG_MANDI_DD},${PO_ORDER_TAG_DIRECT_DELIVERY}`,
            },false);
        } else if (activeBox === 1) {
            getPurchaseOrders({
                page_no:pageNumber+1,
                vendor_id: outletId,
                is_item_required: true,
                fetch_invoice: true,
                fetch_po_with_no_seller_invoice: false,
                order_status: `${ORDER_STATUS_DISPATCHED},${SELLER_ACTION_STATUS_COMPLETED}`,
                seller_action_status: SELLER_ACTION_STATUS_PENDING,
                order_tags :`${PO_ORDER_TAG_MANDI_DD},${PO_ORDER_TAG_DIRECT_DELIVERY}`,
                po_status: PO_STATUS_APPROVED,
            },false);
        } else if (activeBox === 2) {
            getPurchaseOrders({
                page_no:pageNumber+1,
                vendor_id: outletId,
                is_item_required: true,
                fetch_invoice: true,
                fetch_po_with_no_seller_invoice: false,
                seller_action_status: SELLER_ACTION_STATUS_COMPLETED,
                is_payment_details_required: true,
                order_tags :`${PO_ORDER_TAG_MANDI_DD},${PO_ORDER_TAG_DIRECT_DELIVERY}`,
            },false);
        }
        setPageNumber(pageNumber+1)
    }

    return (
        <React.Fragment>
            <Wrapper>
                <WhiteWrapper>
                    <div className="d-flex">
                        <BackButton pathName={"/"}/>
                        <span className="fs-16 fw-600">{strings.allDDPoHeader}</span>
                    </div>
                </WhiteWrapper>
                <div className="d-flex x-scroll p-4">
                    <PoTypeBox
                        id={0}
                        activeBox={activeBox}
                        handleClick={() => handleClick(0)}
                    >
                        {strings.pendingInvoice}
                    </PoTypeBox>
                    <PoTypeBox
                        id={1}
                        activeBox={activeBox}
                        handleClick={() => handleClick(1)}
                    >
                        {strings.pendingDelivery}
                    </PoTypeBox>
                    <PoTypeBox
                        id={2}
                        activeBox={activeBox}
                        handleClick={() => handleClick(2)}
                    >
                        {strings.delivered}
                    </PoTypeBox>
                </div>
                {showLoader && pageNumber===1 ? (
                        <Loader />
                    ) :
                    (<InfiniteScroll
                    className="infinite-scroll"
                    height={"82%"}
                    dataLength={cards.length}
                    hasMore={cards.length < ((activeBox === 0 || activeBox === 1) ? poCount : grnCount)}
                    next={loadNextPage}
                    loader={<Loader isScrollLoader={true}/>}
                >
                    {cards}
                </InfiniteScroll> )}
                <BottomModal
                    visible={openCallModal}
                    onClose={()=>setOpenCallModal(false)}
                    showCloseIcon={true}
                >
                    <FlexColumn className="align-items-center p-4">
                        <Cross color={"#696969"} className="close" onClick={()=>setOpenCallModal(false)}/>
                        <img
                            src={"../logos/po_creator_logo.svg"}
                        />
                        <span className="fs-20 fw-700 mt-4">{poCreator}</span>
                        <span className="fs-14 fw-400 text-gray-cool mt-3 mb-4">PO creator</span>
                        <Button onClick={handleCall}>
                            <span className="fs-13 fw-500 text-white">Call</span>
                        </Button>
                    </FlexColumn>

                </BottomModal>
            </Wrapper>
        </React.Fragment>
    );
};

export default AllDDPoView;
