import React, { useEffect, useState } from "react";
import useVendorStore from "../HomePage/useVendorStore";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  Details, FlexColumn, SushiModal,
  VerticalLine,
  WhiteWrapper,
  Wrapper,
} from "../Common/styles.common";
import { Circle } from "./styles.po";
import { Loader } from "../Common/Loader";
import moment from "moment";
import { strings } from "utils/constants/strings";
import { STATUS_BEGIN, STATUS_DONE } from "utils/constants/others";
import DDPoHeader from "../Header/DDPoHeader";
import {DownloadInvoice} from "../../../operations/operations";
import DownloadIcon from "sushiweb/Icons/all/Download"
import { get } from "styled-system";

const DDPoDetails = (props) => {
  const getPurchaseOrders = useVendorStore((state) => state.getPurchaseOrders);
  const getPurchaseOrderStatus = useVendorStore(
    (state) => state.getPurchaseOrderStatus
  );
  const purchaseOrders = useVendorStore((state) => state.purchaseOrders);
  const getPoGrnMappings = useVendorStore((state) => state.getPoGrnMappings);
  const getPoGrnMappingStatus = useVendorStore(
    (state) => state.getPoGrnMappingStatus
  );
  const [po, setPo] = useState("");
  const [grn, setGrn] = useState("");
  const poGrnList = useVendorStore((state) => state.poGrnList);

  const [items, setItems] = useState("");
  let { source,purchaseOrderId} = useParams();
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);
  const [tonnage,setTonnage] = useState(0);
  const [popupText, setPopupText] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const sellerOrderDetailsStatus = useVendorStore((state) => state.sellerOrderDetailsStatus)
  const getBuyerOrderPodDetails = useVendorStore((state) => state.getBuyerOrderPodDetails)
  const buyerOrdersLinkedtoPO = useVendorStore((state) => state.buyerOrdersLinkedtoPO)
  const [deliveryInvoices, setDeliveryInvoices] = useState([]);

  useEffect(() => {
    if (source === "pod") {
      getPurchaseOrders({
        is_item_required: true,
        purchase_order_id: purchaseOrderId,
      },true);
      getBuyerOrderPodDetails({
        purchase_order_id: purchaseOrderId,
      });
    } else {
      getPoGrnMappings({
        purchase_order_id: purchaseOrderId,
        is_po_grn_items_required: true,
        get_cn_data:true,
      },true);
      getPurchaseOrders({
        is_item_required: true,
        purchase_order_id: purchaseOrderId,
        is_delivery_details_required: false,
      },true);
    }
  }, []);

  useEffect(() => {
    if (getPoGrnMappingStatus === STATUS_DONE && poGrnList) {
      setGrn(poGrnList[0]);
    }
  }, [poGrnList]);

  useEffect(() => {
    if (getPurchaseOrderStatus === STATUS_DONE && purchaseOrders) {
      setPo(purchaseOrders[0]);
      if (source !== "pod") {
        setShowLoader(false);
      }
    }
  }, [purchaseOrders]);

  useEffect(() => {
    if (po.purchase_order_items) {
      setItems(po.purchase_order_items);
    }
  }, [po]);

  useEffect(() => {
    if (getPurchaseOrderStatus === STATUS_BEGIN) {
      setShowLoader(true);
    }
  }, [getPurchaseOrderStatus]);

  useEffect(() => {
    if (getPoGrnMappingStatus === STATUS_BEGIN) {
      setShowLoader(true);
    }
    if (getPoGrnMappingStatus === STATUS_DONE) {
      setShowLoader(false);
    }
  }, [getPoGrnMappingStatus]);


  useEffect(() => {
    if (sellerOrderDetailsStatus === STATUS_DONE) {
      setShowLoader(false);
    }
  }, [sellerOrderDetailsStatus])

  useEffect(() => {
    if (buyerOrdersLinkedtoPO && buyerOrdersLinkedtoPO.length) {
      setShowLoader(false);
    }
    let downloadableInvoices = [];
    for (let i = 0; i < buyerOrdersLinkedtoPO.length; i++) {
      if (buyerOrdersLinkedtoPO[i].buyer_order_delivery_challan.length > 0) {
        {
          downloadableInvoices.push({
            url : buyerOrdersLinkedtoPO[i].buyer_order_delivery_challan,
            fileName : "Delivery_challan_"+buyerOrdersLinkedtoPO[i].buyer_order_number
          })
        }
      }
      if (buyerOrdersLinkedtoPO[i].buyer_order_invoice.length > 0) {
        downloadableInvoices.push({
          url : buyerOrdersLinkedtoPO[i].buyer_order_invoice,
          fileName : "Order_invoice_"+buyerOrdersLinkedtoPO[i].buyer_order_number
        });
      }
    }
    setDeliveryInvoices(downloadableInvoices);
  }, [buyerOrdersLinkedtoPO]);

  useEffect(()=>{
    calculateTonnage();
  },[items])

  const calculateTonnage =()=>{
    let total = 0;
    for(const item of items){
      total=total+ item.quantity_ordered* item.weight_per_packet
    }
    setTonnage(total)
  }

  const navigateToInvoiceForm = () => {
    navigate(`/ddPo/invoice/${po.id}`)
  }

  const navigateToBuyerOrdersList = () => {
    if (po?.is_bor_distribution_enabled) {
      setPopupText("Orders will be marked as delivered automatically");
      togglePopupModel();
    } else {
      navigate(`/ddPo/${purchaseOrderId}/buyer_orders`)
    }
  }

  const togglePopupModel = () => {
    setShowPopup(!showPopup);
  };

  const downloadDeliveryInvoices = () => {
    if (deliveryInvoices.length > 0) {
      for (let i = 0; i < deliveryInvoices.length; i++) {
        downloadPdfFromS3(deliveryInvoices[i].url, deliveryInvoices[i].fileName);
      }
    } else {
      setPopupText("Invoice and delivery challan is not generated yet");
      togglePopupModel();
    }
  }

  const generateInvoiceCTA = () => {
    navigate(`/grn/invoice/dd/${purchaseOrderId}/${grn.id}`);
  };

  function downloadPdfFromS3(s3Url, filename) {
    DownloadInvoice(s3Url, filename);
    if (window && window.downloadFileMessageHandler) {
      window.downloadFileMessageHandler.postMessage(s3Url);
    }
  }

  return (
    <React.Fragment>
      {showLoader ? (
        <Loader />
      ) : (
        <Wrapper>
          <DDPoHeader
              poId={po.id}
              purchaseOrderNumber={po.purchase_order_number}
              itemsLength={items.length}
              tonnage={tonnage}
              source="details"
              orderTag={po.order_tag}
          ></DDPoHeader>
          <div className="d-flex flex-column po-content">
            <Details>
              <div className="d-flex flex-column mx-4">
                <div className="d-flex align-items-center">
                  <Circle>
                    <img
                      src="/logos/rupee_logo.svg"
                      height="16px"
                      width="15px"
                    />
                  </Circle>
                  <span className="fs-14 text-gray-cool ml-3">&#8377;</span>
                  {po && (
                    <span className="fs-14 fw-400 text-gray-cool">
                      {po.po_amount.toLocaleString("en-IN")} {strings.poAmount}
                    </span>
                  )}
                </div>
                <div className="d-flex align-items-center mt-3">
                  <Circle>
                    <img
                      src="/logos/delivery_logo.svg"
                      height="16px"
                      width="15px"
                    />
                  </Circle>
                  {po.expected_delivery_date ? (
                    <span className="fs-14 fw-400 text-gray-cool ml-3">
                      Delivery by{" "}
                      {moment(new Date(po.expected_delivery_date))
                        .format("DD MMM, YYYY")
                        .toString()}
                    </span>
                  ) : (
                    <></>
                  )}
                </div>
                {po.order_tag === "DIRECT_DELIVERY" ? (
                  <div className="d-flex align-items-center mt-3">
                  <Circle>
                    <img
                      src="/logos/location_logo.svg"
                      height="16px"
                      width="15px"
                    />
                  </Circle>
                  <span className="fs-14 fw-400 text-gray-cool ml-3 wd-po-card">
                    {po.buyer_outlet_details?.name}
                  </span>
                </div>
                ):(null)}
                <div className="d-flex align-items-center mt-3">
                  <Circle onClick={() => downloadDeliveryInvoices()}>
                    <DownloadIcon />
                  </Circle>
                  <span className="fs-14 fw-400 text-red-500 ml-3 wd-po-card">
                    Download delivery documents
                  </span>
                </div>
              </div>
            </Details>
            <span className="fs-20 fw-700 ml-4 mt-5">{strings.productDetails}</span>
            <div className="mx-4 my-4">
              {items != "" ? (
                items.map((item, index) => (
                  <div key={index}>
                    <Card className="mb-4">
                      <div className="d-flex mx-4">
                        <img
                          src={item.product_image}
                          height="48px"
                          width="48px"
                          style={{borderRadius:"8px"}}
                        />
                        <div className="d-flex flex-column justify-content-center ml-4">
                          <span className="fs-16 fw-600 mb-1">
                            {item.product_name}
                          </span>
                          <span className="fs-15 fw-400 text-gray-cool">
                            &#8377; {item.price_per_unit}/{item.buy_measurement_unit}
                          </span>
                        </div>
                      </div>

                      <Card hideShadow={true} className="align-items-center mx-4 mt-3">
                        <div className="d-flex wd-100 justify-content-center">
                          <div className="d-flex flex-column wd-50 align-items-center">
                            <span className="fs-15 fw-700">
                              {item.quantity_ordered} {item.buy_measurement_unit}
                            </span>
                            <span className="fs-13 fw-400 text-gray-cool mt-2">
                              {strings.orderQty}
                            </span>
                          </div>
                          <VerticalLine />
                          <div className="d-flex flex-column wd-50 align-items-center">
                            <span className="fs-15 fw-700">
                              &#8377;{(item.quantity_ordered*item.price_per_unit)?.toLocaleString("en-IN")}
                            </span>
                            <span className="fs-13 fw-400 text-gray-cool mt-2">
                              {strings.orderAmount}
                            </span>
                          </div>
                        </div>
                      </Card>

                    </Card>
                  </div>
                ))
              ) : (
                <></>
              )}
            </div>
          </div>
          <WhiteWrapper className="po-bottom">
            { grn?.seller_status != "APPROVED" &&
            grn?.is_invoice_generation_allowed_for_seller_hub && grn?.supplier_bill_number?.length>0 ? (
              <Button
                className="fs-13 text-white fw-600"
                onClick={generateInvoiceCTA}
              >
                Click to Generate Invoice
              </Button>
            ) : po.order_bill_image?.length == 0 ? (
              <Button
                    className="fs-13 text-white fw-600"
                    onClick={navigateToInvoiceForm}
                >
                  Upload invoice
                </Button> ):
                (<Button
                    className="fs-13 text-white fw-600"
                    onClick={navigateToBuyerOrdersList}
                >
                  Click to mark delivered
                </Button>)}
          </WhiteWrapper>
          <SushiModal visible={showPopup} isCentered>
            <FlexColumn className="align-items-center p-5">
              <img src={"/logos/info_logo.svg"} height="80px" width="80px" />
              <span className="fs-22 fw-700 my-4">{popupText}</span>
              <span
                  className="fs-14 fw-500 text-red-500"
                  onClick={togglePopupModel}
              >
            {" "}
                Okay
          </span>
            </FlexColumn>
          </SushiModal>
        </Wrapper>
      )}
    </React.Fragment>
  );
};

export default DDPoDetails;
